import {
  encryptObject,
  decryptObject,
  generateRandomNumber,
} from "./CommonHelper";
export const getDeviceId = () => {
  var deviceId = getLocalStorage("deviceId");
  if (deviceId) {
    return deviceId;
  } else {
    deviceId = generateRandomNumber();
    storeLocalStorage("deviceId", deviceId);
    return deviceId;
  }
};
export const storeLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const getLocalStorage = (key) => {
  localStorage.getItem(key);
};

export const getUserInfo = () => {
  var userInfo = localStorage.getItem("userInfo");
  if (userInfo) {
    userInfo = decryptObject(userInfo);
  }
  return userInfo;
};

export const storeUserInfo = (obj) => {
  var encryptedObj = encryptObject(obj);
  storeLocalStorage("userInfo", encryptedObj);
};

export const getUserAuthToken = (key) => {
  var userInfo = getUserInfo();
  var authorization = "";
  if (userInfo) {
    authorization = userInfo.authToken;
  }
  return authorization;
};
export const removeLocalStorage = (key) => {
  localStorage.removeItem(key);
};
export const removeUserData = () => {
  removeLocalStorage("userInfo");
  removeLocalStorage("deviceId");
};
export const getUserPermissionList = () => {
  const userInfo = getUserInfo();

  if (userInfo.roles && userInfo.roles.length > 0) {
    return userInfo.roles[0].permissions;
  }
  return [];
};
