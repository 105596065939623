export const BRAND_LIST = [
  { slug: "Lexpure", name: "Lexpure" },
  { slug: "Cruze", name: "Cruze" },
];
export const SCANNING_REPORT_INTERVAL = [
  { slug: "daily", name: "Daily" },
  { slug: "weekly", name: "Weekly" },
  { slug: "half_month", name: "Half Month" },
  { slug: "monthly", name: "Monthly" },
];
export const SCANNING_REMINDER_INTERVAL = [
  { slug: "daily", name: "Daily" },
  { slug: "weekly", name: "Weekly" },
  { slug: "half_month", name: "Half Month" },
  { slug: "monthly", name: "Monthly" },
];
export const PRODUCT_TRANSFER_REMINDER_INTERVAL = [
  { slug: "daily", name: "Daily" },
  { slug: "weekly", name: "Weekly" },
  { slug: "half_month", name: "Half Month" },
  { slug: "monthly", name: "Monthly" },
];
export const PRODUCT_CLAIM_REMINDER_INTERVAL = [
  { slug: "daily", name: "Daily" },
  { slug: "weekly", name: "Weekly" },
  { slug: "half_month", name: "Half Month" },
  { slug: "monthly", name: "Monthly" },
];
export const OTHER_PRODUCT_SCAN = [
  { slug: "daily", name: "Daily" },
  { slug: "weekly", name: "Weekly" },
  { slug: "half_month", name: "Half Month" },
  { slug: "monthly", name: "Monthly" },
];
export const ROLE = [
  { slug: "dealer", name: "Dealer" },
  { slug: "retailer", name: "Sub-Dealer" },
  { slug: "technician", name: "Technician" },
];

export const ACHIEVERS_SHOW_FILTER = [
  { slug: "yes", name: "Yes" },
  { slug: "no", name: "No" },
];
export const OUTLET_DISPLAY_SETTING = [
  { slug: "yes", name: "On" },
  { slug: "no", name: "Off" },
];
export const ACHIEVERS_SHOW = [
  { slug: "1", name: "Yes" },
  { slug: "0", name: "No" },
];
export const SCAN_ALLOW_FILTER = [
  { slug: "yes", name: "Yes" },
  { slug: "no", name: "No" },
];
export const SCAN_ALLOW = [
  { slug: "1", name: "Yes" },
  { slug: "0", name: "No" },
];
export const SPECIAL_ONLY_FILTER = [
  { slug: "yes", name: "Yes" },
  { slug: "no", name: "No" },
];
export const SPECIAL_ONLY = [
  { slug: "1", name: "Yes" },
  { slug: "0", name: "No" },
];
export const ACHIEVEMENT_FILTER = [
  { slug: "yes", name: "Yes" },
  { slug: "no", name: "No" },
];
export const STATUS = [
  { slug: "1", name: "1" },
  { slug: "0", name: "0" },
];
export const REWARD_STATUS = [
  { slug: "yes", name: "Active" },
  { slug: "no", name: "Deactive" },
];
export const REWARD_ALLOW_SCAN = [
  { slug: "yes", name: "Yes" },
  { slug: "no", name: "No" },
];
export const BONUS_POINT_ACHIEVED_STATUS = [
  { slug: "yes", name: "Yes" },
  { slug: "no", name: "No" },
];
export const SEMINAR_STATUS = [
  { slug: "yes", name: "Active" },
  { slug: "no", name: "Deactive" },
];
export const ATTEND_SEMINAR = [
  { slug: "yes", name: "Yes" },
  { slug: "no", name: "No" },
];
export const IS_SENT = [
  { slug: "yes", name: "Yes" },
  { slug: "no", name: "No" },
];
export const INTERESTED = [
  { slug: "yes", name: "Yes" },
  { slug: "no", name: "No" },
];
export const ACHIEVEMENT = [
  { slug: "1", name: "Yes" },
  { slug: "0", name: "No" },
];
export const WHATSAPP_MESSAGE_TYPE = [
  { slug: "scanning_report", name: "Scanning Report" },
  { slug: "scan_reminder", name: "Scan Reminder" },
  { slug: "product_transfer_reminder", name: "Product Transfer Reminder" },
  { slug: "product_claim", name: "Product Claim" },
  { slug: "product_transfer_sender", name: "Product Transfer (Sender)" },
  { slug: "product_transfer_receiver", name: "Product Transfer (Receiver)" },
  { slug: "other_product_scan", name: "Other Product Scan" },
  { slug: "birthday_anniversary", name: "Birthday Anniversary" },
  { slug: "reward_gift", name: "Reward Gift" },
];
export const getWhatsappMessageTypeObjBySlug = (t, slug) => {
  let selectedSlug = null;
  const filteredData = WHATSAPP_MESSAGE_TYPE.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const DELIVERY_STATUS = [
  { slug: "Delivered", name: "Delivered" },
  { slug: "Failed", name: "Failed" },
  { slug: "Pending", name: "Pending" },
  { slug: "Read", name: "Read" },
  { slug: "Sent", name: "Sent" },
];
export const ROLE_LIST = (t) => {
  return [
    { slug: "dealer", name: t("dealer") },
    { slug: "retailer", name: t("sub_dealer") },
    { slug: "technician", name: t("technician") },
  ];
};
export const SCAN_REWARD_TYPE_GIFT_SLUG = "gift";
export const SCAN_REWARD_TYPE_BONUS_POINT_SLUG = "bonus_point";
export const SCAN_REWARD_TYPE_LIST = (t) => {
  return [
    { slug: "gift", name: t("gift") },
    { slug: "bonus_point", name: t("bonus_point") },
  ];
};
export const getScanRewardTypeObjBySlug = (t, slug) => {
  let selectedSlug = null;
  const filteredData = SCAN_REWARD_TYPE_LIST(t).filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const SCAN_TYPE_LIST = (t) => {
  return [
    { slug: "box", name: t("box") },
    { slug: "product", name: t("product") },
  ];
};
export const SCAN_CODE_REPORT_TABLE_TYPE_OLD_SLUG = "old";
export const SCAN_CODE_REPORT_TABLE_TYPE_NEW_SLUG = "new";
export const SCAN_CODE_REPORT_TABLE_TYPE = [
  { slug: SCAN_CODE_REPORT_TABLE_TYPE_OLD_SLUG, name: "Old" },
  { slug: SCAN_CODE_REPORT_TABLE_TYPE_NEW_SLUG, name: "New" },
];
export const getScanCodeReportTableTypeObjBySlug = (t, slug) => {
  let selectedSlug = null;
  const filteredData = SCAN_CODE_REPORT_TABLE_TYPE.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
