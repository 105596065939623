import moment from "moment";
import store from "store";
import { renderStr } from "./CommonHelper";
import { settingsSelector } from "components/_selectors/authSelectors";

export const getCurrentDateTime = () => {
  return moment();
};
export const getFormattedDate = (date, dateFormat = "") => {
  if (date == "-") {
    return date;
  }
  if (!dateFormat) {
    dateFormat = "DD/MM/YYYY";
  }
  return moment(date).format(dateFormat);
};
export const getFormattedTime = (dateTime, timeFormat = "h:mm A") => {
  //  var date = moment().format('YYYY-MM-DD')+" "+time;
  return moment(dateTime).format(timeFormat);
};
export const getFormattedDateTime = (date, dateTimeFormat = "") => {
  if (dateTimeFormat == "") {
    dateTimeFormat = "DD/MM/YYYY h:mm A";
  }
  return moment(date).format(dateTimeFormat);
};
export const getTime = (time) => {
  var timeFormat = "h:mm A";
  return moment(time, "HH:mm:ss").format(timeFormat);
};
export const putTime = (timeString) => {
  const date = new Date(`1970-01-01T${timeString}Z`);
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
};
export const manualNotificatioTime = (timeString) => {
  const date = new Date(`1970-01-01T${timeString}Z`);
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const seconds = date.getUTCSeconds().toString().padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
};

export const getServerFormattedDate = (date) => {
  var dateFormat = "YYYY-MM-DD";
  return moment(date).format(dateFormat);
};

export const getYearFormattedDate = (date, dateFormat = "") => {
  if (date == "-") {
    return date;
  }

  dateFormat = "YYYY";
  return moment(date).format(dateFormat);
};
