import React from "react";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { userInfoSelector } from "components/_selectors/authSelectors";
import { getUserAuthToken } from "utils/StorageHelper";
import { mainRoutes as routes, loginRoutes } from "config/routing";
const ProtectedRoute = ({ userInfo, children }) => {
  const user = getUserAuthToken();
  if (!user) {
    // not logged in so redirect to login page with the return url
    return <Navigate to={loginRoutes.login.path} replace />;
  } else {
    return children;
  }
};
const mapStateToProps = (state) => {
  return {
    userInfo: userInfoSelector(state),
  };
};
export default connect(mapStateToProps, {})(ProtectedRoute);
