import React from "react";
import { useEffect, useState } from "react";
import Header from "components/_common/Header"
import Footer from "components/_common/Footer";
import { Outlet } from "react-router-dom";
import ProtectedRoute from "routes/ProtectedRoute";
import Sidebar from "components/_common/Header/Sidebar";
export default function AppLayout(props) {
  return  (
    <ProtectedRoute>
      {/* <!-- left side menus --> */}
      <Sidebar />
      <div className="main-content pb-5">
        <Header />
        <Outlet />
        <Footer />
      </div>
    </ProtectedRoute>
  );
}
