export const userInfoSelector = (state) => state.auth?.userInfo;
export const userLocationSelector = (state) => state.auth.currentLocation;
export const settingsSelector = (state) => state.auth.settings;
export const userProfileSelector = (state) => state.auth.profileData;
export const isLoggedinSelector = (state) => state.auth.userInfo?.authorization;
export const userCurrentRoleSelector = (state) => {
  const roleList = state.auth.userInfo?.roles;
  if (roleList) {
    return roleList[0];
  }
  return null;
};
export const firebaseTokenSelector = (state) => state.auth.firebaseToken;
export const userCurrencySelector = (state) => {
  var settings = settingsSelector(state);
  if (isLoggedinSelector(state)) {
    if (userProfileSelector(state)) {
      return userProfileSelector(state).currency.symbol;
    }
  } else if (settings.currency && settings.currency.length > 0) {
    return settings.currency[0].symbol;
  }
  return "₹";
};
export const currencyCodeSelector = (state) => {
  var settings = settingsSelector(state);
  if (isLoggedinSelector(state)) {
    return userProfileSelector(state).currency.code;
  } else if (settings.currency && settings.currency.length > 0) {
    return settings.currency[0].code;
  }
  return "INR";
};
export const countryCodeSelector = (state) => {
  var settings = settingsSelector(state);
  // if(isLoggedinSelector(state)){
  //     return userProfileSelector(state).currency.code
  // }
  if (settings.currency && settings.currency.length > 0) {
    return settings.country[0].code;
  }
  return "IN";
};
export const languageSelector = (state) => {
  var settings = settingsSelector(state);
  if (settings.languages[0]) {
    return settings.languages[0].id;
  }
  return "1";
};
