import store from "store";
import APIService from "services/api.service";
import api from "config/api";
import { successMessage, errorMessage } from "utils/ToastNotificationHelper";
import {
  getUserInfo,
  storeUserInfo,
  removeLocalStorage,
} from "utils/StorageHelper";
import { useTranslation } from "react-i18next";
export const getListData = async (apiurl, method, data, callback) => {
  // const response = await APIService.callAPI(apiurl, method, data);
  // if(response){
  //   callback(response.status,response);
  // }
  const response = await APIService.getData(apiurl, data);
  if (response) {
    callback(response.status, response);
  }
};
export const manageAPICall = async (apiurl, method, data, callback) => {
  var response;
  if (method == "GET") {
    response = await APIService.getData(apiurl, data);
  } else if (method == "POST") {
    response = await APIService.submitData(apiurl, data);
  } else if (method == "PATCH") {
    response = await APIService.submitPatchData(apiurl, data);
  } else if (method == "DELETE") {
    response = await APIService.deleteData(apiurl, data);
  } else if (method == "POSTFORMDATA") {
    response = await APIService.submitFormData(apiurl, data);
  }
  if (response) {
    if (callback) {
      callback(response.status, response);
    } else {
      return response;
    }
  }
};
export const countryList = async (params, callback) => {
  var payload = {
    ...params,
    ...{ sort_by: "name", sort_by_mode: "asc" },
  };
  const response = await manageAPICall(
    api.countryList.url,
    api.countryList.method,
    payload
  );
  if (response && response.status) {
    callback(response.data);
  }
};
export const stateList = async (params, callback) => {
  var payload = {
    ...params,
    ...{ sort_by: "name", sort_by_mode: "asc" },
  };
  const response = await manageAPICall(
    api.stateList.url,
    api.stateList.method,
    payload
  );
  if (response && response.status) {
    callback(response.data);
  }
};
export const cityList = async (params, callback) => {
  var payload = {
    ...params,
    ...{ sort_by: "name", sort_by_mode: "asc" },
  };
  const response = await manageAPICall(
    api.cityList.url,
    api.cityList.method,
    payload
  );
  if (response && response.status) {
    callback(response.data);
  }
};
export const categoryOptions = (inputValue, setDisabled = false) =>
  new Promise((resolve) => {
    const payload = {
      category: inputValue,
      limit: 10,
    };
    categoryList(payload, (responseData) => {
      const listData = [];
      responseData.data.forEach(function (element) {
        let listElement = element;
        // if (setDisabled) {
        //   listElement = {
        //     ...listElement,
        //     ...{ isDisabled: element.status ? false : true },
        //   };
        // }
        listData.push(listElement);
      });
      resolve(listData);
    });
  });
export const categoryList = async (params, callback) => {
  var payload = {
    limit: 50,
    ...params,
  };
  const response = await manageAPICall(
    api.categoryReportList.url,
    api.categoryReportList.method,
    payload
  );
  if (response && response.status) {
    callback(response.data);
  }
};
export const scanCategoryList = async (params, callback) => {
  var payload = {
    scan_allow: "yes",
    limit: 50,
    order_by: "id",
    distinct: "category",
    ...params,
  };
  const response = await manageAPICall(
    api.categoryReportList.url,
    api.categoryReportList.method,
    payload
  );
  if (response) {
    callback(response.status, response);
  }
};
export const noScanCategoryList = async (params, callback) => {
  var payload = {
    special_only: "no",
    limit: 50,
    order_by: "id",
    distinct: "category",
    ...params,
  };
  const response = await manageAPICall(
    api.categoryReportList.url,
    api.categoryReportList.method,
    payload
  );
  if (response) {
    callback(response.status, response);
  }
};
export const userList = async (params, callback) => {
  var payload = params;
  const response = await manageAPICall(
    api.userList.url,
    api.userList.method,
    payload
  );
  if (response && response.status) {
    callback(response.data);
  }
};
export const roleList = async (params, callback) => {
  const payload = params;
  const response = await manageAPICall(
    api.roleList.url,
    api.roleList.method,
    payload
  );
  if (response && response.status) {
    callback(response.data);
  }
};
export const roleOptions = (inputValue, setDisabled = false) =>
  new Promise((resolve) => {
    const payload = {
      filters: {
        name: inputValue,
      },
      limit: 10,
    };
    roleList(payload, (responseData) => {
      const listData = [];
      responseData.data.forEach(function (element) {
        let listElement = element;
        if (setDisabled) {
          listElement = {
            ...listElement,
            ...{ isDisabled: element.status ? false : true },
          };
        }
        listData.push(listElement);
      });
      resolve(listData);
    });
  });
