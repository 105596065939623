import logo from './logo.svg';
import './App.css';
import {NotificationContainer} from 'react-notifications';
import AppRouteWrapper from 'routes/AppRouteWrapper';
import { HelmetProvider } from 'react-helmet-async';
import { useEffect, useLayoutEffect } from 'react';
import { NavigationType, useLocation, useNavigationType } from 'react-router-dom';
import ErrorBoundary from 'components/_common/ErrorBoundary/ErrorBoundary';
import { routeScrollToTop } from 'utils/CommonHelper';
import 'react-notifications/lib/notifications.css';
import "components/_common/CrudSections/antd.css"
import 'assets/styles/argon-dashboard.css';
import 'assets/styles/master.css';
import 'assets/styles/developer.css';
const useBackButton = () => {
  const navType = useNavigationType();
  return navType === NavigationType.Pop;
};
// scroll to top on route change
const Wrapper = ({children}) => {
  const location = useLocation();
  const isPop = useBackButton();
  useLayoutEffect(() => {
    // every route change
    routeScrollToTop();
  }, [location.pathname, isPop]);
  return children
} 
function App() {
  return (
    <HelmetProvider>
    <ErrorBoundary>
      <NotificationContainer />
      <Wrapper>
        <AppRouteWrapper/>
      </Wrapper>
    </ErrorBoundary>
  </HelmetProvider>
  );
}

export default App;
