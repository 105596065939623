import http from "./axiosConfig";
import { errorMessage } from "utils/ToastNotificationHelper";
import { getUserAuthToken } from "utils/StorageHelper";
import config from "config/config";
import qs from "qs";
class APIService {
  addHeaders(token) {
    http.defaults.headers.common["Authorization"] = "Bearer " + token;
    http.defaults.headers.common["X-Language-Id"] = 1;
    http.defaults.headers.common["ngrok-skip-browser-warning"] = "69420";
  }
  async getData(url, data) {
    try {
      const token = getUserAuthToken();
      if (token) {
        this.addHeaders(token);
      }
      var res = await http.get(`${url}${(data)?'?'+qs.stringify(data):''}`);
      if (res) {
        return Promise.resolve(res.data);
      }
    } catch (err) {
      console.log(err);
      if (err?.response?.status != 401) {
        errorMessage("Something went wrong!");
        return Promise.reject(err);
      }
    }
  }
  async submitData(url, data) {
    try {
      const token = getUserAuthToken();
      if (token) {
        this.addHeaders(token);
      }
      var res = await http.post(url, data);
      return res.data;
    } catch (err) {
      console.log(err);
      if (err?.response?.status != 401) {
        return {
          status: false,
          message: "Something went wrong!",
        };
      }
    }
  }
  async submitPatchData(url, data) {
    try {
      const token = getUserAuthToken();
      if (token) {
        this.addHeaders(token);
      }
      var res = await http.patch(url, data);
      return res.data;
    } catch (err) {
      console.log(err);
      if (err?.response?.status != 401) {
        return {
          status: false,
          message: "Something went wrong!",
        };
      }
    }
  }
  async deleteData(url, data) {
    try {
      const token = getUserAuthToken();
      if (token) {
        this.addHeaders(token);
      }
      var res = await http.delete(url, data);
      return res.data;
    } catch (err) {
      console.log(err);
      if (err?.response?.status != 401) {
        return {
          status: false,
          message: "Something went wrong!",
        };
      }
    }
  }
  async submitPublicTokenData(url, data, token="") {
    try {
      if (token) {
        this.addHeaders(token);
      }
      var res = await http.post(url, data);
      return res.data;
    } catch (err) {
      console.log(err);
      if (err?.response?.status != 401) {
        return {
          status: false,
          message: "Something went wrong!",
        };
      }
    }
  }
  async submitFormData(url,data){
		try {
			const token = getUserAuthToken();
			if(token){
				this.addHeaders(token);
			} 
			var res = await http.post(url, data,
				{ headers: {
				"Content-Type": "multipart/form-data",
			  	}}
			  );
			  return res.data;
	  } catch (err) {
		  console.log(err);
      if (err?.response?.status != 401) {
        return {
          status: false,
          message: "Something went wrong!",
        };
      }
	  }	
	}
}
export default new APIService();
