import api from "config/api";
import { manageAPICall } from "./CommonCrudAction";
import { getUserInfo, storeUserInfo } from "utils/StorageHelper";
import { removeUserData } from "utils/StorageHelper";
import { t } from "i18next";
import { successMessage } from "utils/ToastNotificationHelper";
import store from "store";
import { errorMessage } from "utils/ToastNotificationHelper";

export const loginUser = async (data, callback) => {
  manageAPICall(api.login.url, api.login.method, data, callback);
};
export const forgotPasswordCall = async (data, callback) => {
  manageAPICall(
    api.forgotPassword.url,
    api.forgotPassword.method,
    data,
    callback
  );
};
export const updateProfile = async (data, callback) => {
  manageAPICall(api.updateUser.url, api.updateUser.method, data, callback);
};
export const getProfileDetails = async (data, callback) => {
  manageAPICall(
    api.getProfileDetails.url,
    api.getProfileDetails.method,
    data,
    callback
  );
};
export const refreshToken = async (callback) => {
  manageAPICall(api.refreshToken.url, {}, (status, response) => {
    // console.log(response);
    if (response) {
      if (status) {
        var obj = getUserInfo();
        obj.token = response.data?.token;
        storeUserInfo(obj);
        callback();
      } else {
        console.log("error");
      }
    }
  });
};
// export const logoutUser = async (callback) => {
//   const response = await APIService.getData(api.logout.url);
//   if (response) {
//     if (response.status) {
//       removeUserSessionData();
//       callback();
//     } else {
//       errorMessage(response.message);
//     }
//   }
// };

export const logoutUser = async (callback) => {
  manageAPICall(api.logout.url, api.logout.method, {}, (status, response) => {
    if (response) {
      if (response.status) {
        successMessage(t("logout_success_msg"));
        removeUserData();
        store.dispatch({
          type: "UPDATE_USER_DETAILS",
          payload: {},
        });
        callback();
      } else {
        errorMessage(response.message);
      }
    }
  });
};
