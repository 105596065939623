import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { mainRoutes, loginRoutes } from "config/routing";
import AppLayout from "layouts/AppLayout";
import Error404 from "components/_common/ErrorPages/Error404";
import { PreloaderComponent } from "components/_common/Preloader";
import SigninLayout from "layouts/SigninLayout";
export default function AppRouteWrapper() {
  var routeKeys = Object.keys(mainRoutes);
  var loginRouteKeys = Object.keys(loginRoutes);
  return (
    <Suspense fallback={<PreloaderComponent />}>
      <Routes>
        <Route element={<AppLayout />}>
          {routeKeys.map((routeKey, i) => {
            return (
              <Route
                key={i}
                path={`${mainRoutes[routeKey].path}${
                  mainRoutes[routeKey].routes &&
                  Object.keys(mainRoutes[routeKey].routes) > 0
                    ? "/*"
                    : ""
                }`}
                element={mainRoutes[routeKey].element}
              >
                {mainRoutes[routeKey].routes &&
                  Object.keys(mainRoutes[routeKey].routes).map(
                    (nestedRouteKey, nestedIndex) => (
                      <Route
                        key={nestedIndex}
                        path={mainRoutes[routeKey].routes[nestedRouteKey].path}
                        element={
                          mainRoutes[routeKey].routes[nestedRouteKey].element
                        }
                      />
                    )
                  )}
              </Route>
            );
          })}
        </Route>
        <Route element={<SigninLayout />}>
          {loginRouteKeys.map((routeKey, i) => {
            return (
              <Route
                key={i}
                path={loginRoutes[routeKey].path}
                element={loginRoutes[routeKey].element}
              />
            );
          })}
        </Route>
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Suspense>
  );
}
